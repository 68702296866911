export const regexPatterns = {
    VALID_NAME_PATTEREN:
        /^(?!.*\.\.)([a-zA-Zà-žÀ-Ž]+(\.[a-zA-Zà-žÀ-Ž]+)*\.?)(\s[a-zA-Zà-žÀ-Ž]+(\.[a-zA-Zà-žÀ-Ž]+)*\.?)*$/,
    VALID_IFSC: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
    VALID_PAN: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
    VALID_INDIVIDUAL_PAN: /^[A-Za-z]{3}[Pp][A-Za-z]{1}\d{4}[A-Za-z]{1}$/,
    VALID_HUF_PAN: /^[A-Za-z]{3}[Hh][A-Za-z]{1}\d{4}[A-Za-z]{1}$/,
    VALID_CORPORATE_PAN: /^[A-Za-z]{3}[A-GI-OQ-Za-gi-oq-z][A-Za-z]{1}\d{4}[A-Za-z]{1}$/,
    ALLOW_EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*\.[a-zA-Z]{2,5}$/,
    ALLOW_OPTIONAL_EMAIL: /^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*\.[a-zA-Z]{2,5}$/,
    VALID_URL: /^https?:\/\/(?:www\.)?[^\s\/$.?#].[^\s]*$/,
    VALID_ALPHANUMERIC: /^[a-zA-Z0-9]+$/,
    VALID_TEXT: /^\s*[^ \t\n\r\f\v]+.*$/,
    VALID_DEMAT: /^\d{16}$|^\b[Ii][Nn]\d{14}$/,
    VALID_CIN: /^[LUlu][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6}$/,
    VALID_TIN: /^[0-9]{11}$/,
    VALID_KARTA_PAN: /^[A-Za-z]{3}[Pp][A-Za-z][0-9]{4}[A-Za-z]$/,
    VALID_LLPIN: /^[Aa]{2}[A-Za-z]{1}[0-9]{4}$/,
    VALID_RELATIONSHIP: /^[A-Za-z-\s]*$/,
    VALID_ISIN: /^([A-Za-z]{2})([A-Za-z0-9]{9})([0-9]{1})$/,
    ALLOW_ONLY_4_DECIMALS: /^\d+(\.\d{4})$/,
    ALLOW_NEGATIVE_POSITIVE_4_DECIMALS: /^\-?\d+(\.\d{1,4})?$/,
    ALLOW_UPTO_4_DECIMALS: /^\d+(\.\d{1,4})?$/,
    VALID_INDIAN_MOBILE_NUMBER: /^[6-9]\d{9}$/,
};
